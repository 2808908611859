.header {
  height: var(--header-height);
  max-width: var(--max-section-width);
  width: var(--section-width);
  margin: 0 auto;
  justify-content: space-between;
}

.logo {
  font-size: var(--fs-logo);
}

@media (max-width: 600px) {
  .header {
    height: var(--header-height-mobile);
  }
}
